import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Look Look" />
    <header className="looklook-header">
      <Link to="/">
        <h1>LOOK</h1>
        <h2>LOOK</h2>
      </Link>
    </header>
    <div className="looklook">
      <a
        href="https://www.instagram.com/matthewmiskol/"
        target="_blank"
        className="miskol"
      >
        Matthew Miskol
      </a>
      <a
        href="https://www.instagram.com/littlenickytattoos/"
        target="_blank"
        className="chang"
      >
        Nick Gargiulo
      </a>
      <a
        href="https://www.instagram.com/cjfishburntattooing/"
        target="_blank"
        className="fishburn"
      >
        cj fishburn
      </a>
      <a className="fishburn" href="https://shop.looklooktattoo.com/">
        SHOP
      </a>
    </div>
    <div className="information">
      <span>2008 S. 1100 E. Salt Lake City</span>
      <span>801-419-0508</span>
      <div>
        <Link className="link" to="/tattoo-aftercare/">
          Tattoo Aftercare
        </Link>
      </div>
      <span>-</span>
      <span>Walk-ins Always Welcome</span>
      <span>Open All Week ~ Noon - 8pm</span>
    </div>
  </Layout>
);

export default IndexPage;
